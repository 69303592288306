import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import has from "lodash/has";

import { richTextResolver } from "../../CMS/Storyblok";
import LinkSecondary from "../Links/LinkSecondary";
import DecorationSquare from "../Decorations/DecorationSquare";
import DecorationLetter from "../Decorations/DecorationLetter";

import Sales from "../../assets/img/ExploreProgrammes/Sales.png";
import SalesMobile from "../../assets/img/ExploreProgrammes/Sales-mobile.png";

import styleColors from "./ExploreOurProgrammesSection.scss";

const ExploreOurProgrammesSection = ({
                                       section,
                                       pageName,
                                       component,
                                       sectionData,
                                       isExpanded,
                                     }) => {
  const [isDescriptionScroll, setIsDescriptionScroll] = useState(false);
  const [isCardActive, setCardActive] = useState(false);
  const refHolder = useRef(null);
  const refContent = useRef(null);
  const isMobile = window.mobileCheck();

  useEffect(() => {
    const holderHeight = refHolder.current.clientHeight;
    const contentHeight = refContent.current.clientHeight;
    if (holderHeight < contentHeight) setIsDescriptionScroll(true);
  }, [sectionData]);

  const isProgrammes = component === "programmes";
  const isSoonProgrammes = component === "soon_programmes";
  const isCareerSeekersProgrammes = component === "career_seekers_programmes";
  const isEmployersProgrammes = component === "employers_programmes";

  let mainColor;
  let mainImage = null;
  let mainImageMobile = null;
  let serverRequest = null;

  if (!isSoonProgrammes) {
    mainColor = has(section, "content.program_background_color")
      ? section.content.program_background_color.color
      : styleColors.auctioneering;

    mainImage = has(section, "content.program_card_image")
      ? section.content.program_card_image
      : Sales;

    const pathLink = isCareerSeekersProgrammes
      ? "/career-seekers/get-started/learn-more/"
      : "/employers/how-to-apply/select/";

    mainImageMobile = has(section, "content.program_card_image_mobile")
      ? section.content.program_card_image_mobile
      : SalesMobile;

    if (isProgrammes) {
      serverRequest =
        pageName !== "potentialEmployersPage"
          ? section.content.program_career_seeker_view
          : section.content.program_employers_view;
    } else if (
      (isCareerSeekersProgrammes || isEmployersProgrammes) &&
      sectionData.length
    ) {
      const sectionList = section.content.program_section_blocks.map((programSection) => {
        const uuid = typeof programSection === "object"
          ? programSection.uuid
          : programSection; // it's just an uuid directly

        const findSectionIndex = sectionData.findIndex(
          (item) => item.uuid === uuid,
        );

        return (
          <li key={uuid}>
            {/*
            There is a bug somewhere here that is triggered during unmounting this component,
            therefore I had to change <Link> to a regular <a> so the page reloads on the new page and the bug is skipped.
            It's not ideal but I wasn't able to find the real cause, just this workaround.
            */}
            {/* <Link */}
            {/* to={`${pathLink}${section.slug}/${sectionData[findSectionIndex].slug}`} */}
            {/* > */}
            <a
              href={`${pathLink}${section.slug}/${sectionData[findSectionIndex].slug}`}
            >
              {has(sectionData, [
                findSectionIndex,
                "content",
                "section_title",
              ]) && sectionData[findSectionIndex].content.section_title}
            </a>
            {/* </Link> */}
          </li>
        );
      });

      serverRequest = <ul>{sectionList}</ul>;
    }
  } else {
    mainColor = styleColors["green-300"];
    serverRequest = section.content.program_description;
  }

  const mainClass = classNames({
    "explore-programmes__item": true,
    "soon-program": isSoonProgrammes,
    "active-program": isCardActive,
    isExpanded,
  });

  const descriptionClass = classNames({
    "explore-programmes__description": true,
    "scroll-active": !isDescriptionScroll,
  });

  const handleScroll = () => (event) => {
    if (event) event.preventDefault();
    setIsDescriptionScroll(false);
  };

  const handleActive = () => {
    if (isMobile && !isCardActive) {
      setCardActive(true);
    }
  };

  return (
    <section
      className={mainClass}
      style={{ backgroundColor: mainColor }}
      onClick={handleActive}
    >
      <div className="explore-programmes__frame">
        {!isSoonProgrammes && (
          <>
            <picture className="explore-programmes__visual">
              <source srcSet={mainImage} media="(min-width: 768px)" />
              <img src={mainImageMobile} alt={section.content.title} />
              <DecorationLetter color={mainColor} />
            </picture>
            <div className="explore-programmes__bottom-panel">
              <h5>{section.content.program_title}</h5>
              <LinkSecondary color={"#fff"} text={"View More"} href={"/"} />
            </div>
          </>
        )}

        <span className="mask" style={{ backgroundColor: mainColor }} />

        <div className="explore-programmes__modal-panel">
          <h5>{section.content.program_title}</h5>
          <div className={descriptionClass} ref={refHolder}>
            <div className="frame" ref={refContent}>
              {!(isCareerSeekersProgrammes || isEmployersProgrammes) && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: richTextResolver(serverRequest),
                  }}
                />
              )}
              {(isCareerSeekersProgrammes || isEmployersProgrammes) &&
              serverRequest}
            </div>
          </div>
          {isDescriptionScroll && (
            <LinkSecondary
              color={styleColors["green-200"]}
              text={"Show More"}
              href={"/"}
              handleOnClick={handleScroll()}
            />
          )}
        </div>
      </div>
      <DecorationSquare color={mainColor} size={66} />
    </section>
  );
};

ExploreOurProgrammesSection.propTypes = {
  section: PropTypes.object,
  sectionData: PropTypes.array,
  pageName: PropTypes.string.isRequired,
  component: PropTypes.string,
  index: PropTypes.number,
  isExpanded: PropTypes.bool,
};

export default ExploreOurProgrammesSection;
